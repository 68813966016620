import * as React from "react";
// import { DataGrid } from "@mui/x-data-grid";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import ModifyOrderModal from "./ModifyOrderModal";
import ModifyOrderModalForDriver from "./ModifyOrderModalForDriver";

export default function DataTable({ rows, columns, isDriver }) {
	const [isOrderModalOpen, setIsOrderModalOpen] = React.useState(false);
	const [editOrder, setEditOrder] = React.useState({});

	return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<TableHead>
					<TableRow>
						{columns.map((col) => (
							<TableCell key={col.field}>
								{col.headerName}
							</TableCell>
						))}
						<TableCell>Action</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<TableRow
							key={row.id}
							sx={{
								"&:last-child td, &:last-child th": {
									border: 0
								}
							}}
						>
							<TableCell component="th" scope="row">
								{new Date(
									row.dateTime || undefined
								).toUTCString({})}
							</TableCell>
							<TableCell align="right">
								{row.driverName}
							</TableCell>
							<TableCell align="right">
								{row.pickUpLocation}
							</TableCell>
							<TableCell align="right">
								{row.dropOfLocation}
							</TableCell>
							<TableCell align="right">{row.status}</TableCell>
							<TableCell align="right">
								<Button
									variant="contained"
									onClick={() => {
										setEditOrder(row.rowData);
										setIsOrderModalOpen(true);
									}}
								>
									Edit
								</Button>
								<Button
									disabled
									color="error"
									variant="contained"
								>
									Delete
								</Button>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			{isDriver ? (
				<ModifyOrderModalForDriver
					open={isOrderModalOpen}
					handleClose={() => setIsOrderModalOpen(false)}
					order={editOrder}
				/>
			) : (
				<ModifyOrderModal
					open={isOrderModalOpen}
					handleClose={() => setIsOrderModalOpen(false)}
					editMode={true}
					order={editOrder}
				/>
			)}
		</TableContainer>
	);
}
