import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { API, graphqlOperation } from "aws-amplify";
import { updateOrder } from "./graphql/mutations";
import { STATUS } from "./ModifyOrderModal";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4
};
const getOrderStatus = (orderStatus) => {
	switch (orderStatus) {
		case STATUS.unConfirmed:
			return STATUS.confirmed;
		case STATUS.confirmed:
			return STATUS.inTransit;
		case STATUS.inTransit:
			return STATUS.complete;
		case STATUS.complete:
			return STATUS.complete;
		default:
			return orderStatus;
	}
};

export default function ModifyOrderModalForDriver(
	{ order, open, handleClose } = { order: {} }
) {
	const [note, setNote] = React.useState(order.note);

	async function createOrderHandler() {
		const newOrder = {
			note,
			status: getOrderStatus(order.status)
		};

		try {
			await API.graphql(
				graphqlOperation(updateOrder, {
					input: { ...newOrder, id: order.id }
				})
			);

			handleClose();
		} catch (error) {
			console.log({ error });
		}
	}
	const {
		orderType,
		dateTimePicker,
		orderDriver,
		pickUpLocationId,
		dropOffLocationId,
		containerNumber,
		sealNumber,
		status
	} = order;
	console.log({ order });
	return (
		<Modal
			open={Boolean(open)}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<Typography id="modal-modal-title" variant="h6" component="h2">
					"Edit Order"
				</Typography>
				<Grid
					container
					spacing={2}
					id="modal-modal-description"
					sx={{ mt: 2 }}
				>
					<Grid item xs={12} md={6}>
						<DateTimePicker
							label="Date&Time picker"
							value={dateTimePicker}
							readOnly
							onChange={() => ""}
							renderInput={(params) => (
								<TextField
									{...params}
									InputProps={{
										readOnly: true
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							fullWidth
							label="Order Type"
							variant="outlined"
							value={orderType}
							InputProps={{
								readOnly: true
							}}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							value={orderDriver}
							label="Driver"
							InputProps={{
								readOnly: true
							}}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							value={sealNumber}
							label="Seal Number"
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							label="Pick Up Location"
							variant="outlined"
							value={pickUpLocationId}
							InputProps={{
								readOnly: true
							}}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							label="Drop off Location"
							variant="outlined"
							value={dropOffLocationId}
							InputProps={{
								readOnly: true
							}}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							value={containerNumber}
							label="Container Number"
							variant="outlined"
							InputProps={{
								readOnly: true
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							value={note}
							label="Note"
							variant="outlined"
							onChange={({ target: { value } }) => setNote(value)}
						/>
					</Grid>
					<Grid item xs={12} alignItems="center">
						<Button
							value="Create Order"
							onClick={() => createOrderHandler()}
							variant="contained"
							sx={{ display: "block", margin: "auto" }}
						>
							{getOrderStatus(status) + " Order"}
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Modal>
	);
}
