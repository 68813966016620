/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateDriver = /* GraphQL */ `
  subscription OnCreateDriver {
    onCreateDriver {
      name
      email
      orders {
        items {
          sealNumber
          containerNumber
          note
          dateTime
          type
          pickUpLocation
          dropOffLocation
          status
          id
          createdAt
          updatedAt
          driverOrdersId
        }
        nextToken
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDriver = /* GraphQL */ `
  subscription OnUpdateDriver {
    onUpdateDriver {
      name
      email
      orders {
        items {
          sealNumber
          containerNumber
          note
          dateTime
          type
          pickUpLocation
          dropOffLocation
          status
          id
          createdAt
          updatedAt
          driverOrdersId
        }
        nextToken
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDriver = /* GraphQL */ `
  subscription OnDeleteDriver {
    onDeleteDriver {
      name
      email
      orders {
        items {
          sealNumber
          containerNumber
          note
          dateTime
          type
          pickUpLocation
          dropOffLocation
          status
          id
          createdAt
          updatedAt
          driverOrdersId
        }
        nextToken
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOrder = /* GraphQL */ `
  subscription OnCreateOrder {
    onCreateOrder {
      sealNumber
      containerNumber
      note
      dateTime
      type
      pickUpLocation
      dropOffLocation
      driver {
        name
        email
        orders {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      status
      id
      createdAt
      updatedAt
      driverOrdersId
    }
  }
`;
export const onUpdateOrder = /* GraphQL */ `
  subscription OnUpdateOrder {
    onUpdateOrder {
      sealNumber
      containerNumber
      note
      dateTime
      type
      pickUpLocation
      dropOffLocation
      driver {
        name
        email
        orders {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      status
      id
      createdAt
      updatedAt
      driverOrdersId
    }
  }
`;
export const onDeleteOrder = /* GraphQL */ `
  subscription OnDeleteOrder {
    onDeleteOrder {
      sealNumber
      containerNumber
      note
      dateTime
      type
      pickUpLocation
      dropOffLocation
      driver {
        name
        email
        orders {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      status
      id
      createdAt
      updatedAt
      driverOrdersId
    }
  }
`;
export const onCreateLocation = /* GraphQL */ `
  subscription OnCreateLocation {
    onCreateLocation {
      name
      code
      id
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLocation = /* GraphQL */ `
  subscription OnUpdateLocation {
    onUpdateLocation {
      name
      code
      id
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLocation = /* GraphQL */ `
  subscription OnDeleteLocation {
    onDeleteLocation {
      name
      code
      id
      createdAt
      updatedAt
    }
  }
`;
