import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
import AWS from "aws-sdk";
import awsconfig from "./aws-exports";
import { Auth } from "aws-amplify";

AWS.config.update({
	accessKeyId: "AKIAU5FPP6RHLFRPEGPE",
	secretAccessKey: "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"
});

const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider({
	apiVersion: "2016-04-18",
	region: awsconfig.aws_project_region,
	...awsconfig
});

const userPoolId = awsconfig.aws_user_pools_id;
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4
};

async function signUp({ username, password, email, phone_number, fullname }) {
	try {
		// const { user } = await cognitoIdentityServiceProvider
		// 	.adminCreateUser({
		// 		UserPoolId: userPoolId,
		// 		TemporaryPassword: password,
		// 		Username: username,
		// 		DesiredDeliveryMediums: ["EMAIL"],
		// 		UserAttributes: [
		// 			{
		// 				Name: "email",
		// 				Value: email
		// 			},
		// 			{
		// 				Name: "name",
		// 				Value: fullname
		// 			},
		// 			{
		// 				Name: "phone_number",
		// 				Value: phone_number
		// 			}
		// 		]
		// 	})
		// 	.promise();
		const { user } = await Auth.signUp({
			username,
			password,
			attributes: {
				email, // optional
				phone_number, // optional - E.164 number convention
				name: fullname
			}
		});
		console.log(user);
	} catch (error) {
		console.log("error signing up:", error);
	}
}

export default function ModifyDriverModal() {
	const [open, setOpen] = React.useState(false);
	const [form, setForm] = React.useState({
		username: "",
		password: "tempPassword1",
		email: "",
		phone_number: "",
		fullname: ""
	});

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const createDriverHanlder = () =>
		signUp(form).then((res) => {
			handleClose();
		});
	const handleFormChange = (name, value) =>
		setForm({
			...form,
			[name]: value
		});
	const { username, password, email, fullname } = form;
	return (
		<ListItem disablePadding>
			<ListItemButton onClick={handleOpen}>
				<ListItemIcon>
					<InboxIcon />
				</ListItemIcon>
				<ListItemText primary={"Create Driver"} />
			</ListItemButton>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography
						id="modal-modal-title"
						variant="h6"
						component="h2"
					>
						Create Driver
					</Typography>
					<Grid
						container
						spacing={2}
						id="modal-modal-description"
						sx={{ mt: 2 }}
					>
						<Grid item xs={12} md={6}>
							<TextField
								value={fullname}
								label="Full Name"
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField
								value={email}
								label="Email"
								variant="outlined"
								type="email"
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<TextField
								value={username}
								onChange={({ target: { value } }) =>
									handleFormChange("username", value)
								}
								label="Username"
								variant="outlined"
								required
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField
								value={password}
								label="Password"
								variant="outlined"
								disabled
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<MuiPhoneNumber
								defaultCountry={"us"}
								onChange={(value) =>
									handleFormChange("phone_number", value)
								}
							/>
							,
						</Grid>
						<Grid item xs={12} alignItems="center">
							<Button
								value="Create Driver"
								onClick={createDriverHanlder}
								variant="contained"
								sx={{ display: "block", margin: "auto" }}
							>
								Create Driver
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</ListItem>
	);
}
