import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { API, graphqlOperation } from "aws-amplify";
import { createLocation } from "./graphql/mutations";
import { Button } from "@mui/material";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4
};

export default function ModifyLocationModal() {
	const [open, setOpen] = React.useState(false);
	const [form, setForm] = React.useState({
		name: "",
		code: ""
	});
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const handleFormChange = (name, value) =>
		setForm({
			...form,
			[name]: value
		});
	const { name, code } = form;

	async function createLocationHanlder(input) {
		const location = {
			name: input.name,
			code: input.code
		};
		try {
			await API.graphql(
				graphqlOperation(createLocation, { input: location })
			);
			handleClose();
		} catch (e) {
			consol.log(e);
		}
	}

	return (
		<ListItem disablePadding>
			<ListItemButton onClick={handleOpen}>
				<ListItemIcon>
					<InboxIcon />
				</ListItemIcon>
				<ListItemText primary={"Create Location"} />
			</ListItemButton>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography
						id="modal-modal-title"
						variant="h6"
						component="h2"
					>
						Create Location
					</Typography>
					<Grid
						container
						spacing={2}
						id="modal-modal-description"
						sx={{ mt: 2 }}
					>
						<Grid item xs={12} md={6}>
							<TextField
								value={name}
								onChange={({ target: { value } }) =>
									handleFormChange("name", value)
								}
								label="Name"
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField
								value={code}
								onChange={({ target: { value } }) =>
									handleFormChange("code", value)
								}
								label="Code"
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={12} alignItems="center">
							<Button
								value="Create Order"
								onClick={() => createLocationHanlder(form)}
								variant="contained"
								sx={{ display: "block", margin: "auto" }}
							>
								Create Location
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</ListItem>
	);
}
