import * as React from "react";
import "./styles.css";
import Box from "@mui/material/Box";
import HeaderAppBar from "./Header";
import DataTable from "./DataTable";
import Card from "./Card";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { API, graphqlOperation } from "aws-amplify";
import { listOrders } from "./graphql/queries";
import { onCreateOrder, onUpdateOrder } from "./graphql/subscriptions";
import { STATUS } from "./ModifyOrderModal.js";
const getDriver = /* GraphQL */ `
	query GetDriver($id: ID!) {
		getDriver(id: $id) {
			fullName
			email
			phoneNumber
			orders {
				items {
					dateTime
					type
					containerNumber
					sealNumber
					note
					status
					id
					createdAt
					updatedAt
					driverOrdersId
					orderPickUpLocationId
					orderDropOffLocationId
					driver {
						fullName
						id
					}
					pickUpLocation {
						code
						name
						id
					}
					dropOffLocation {
						code
						name
						id
					}
				}
				nextToken
			}
			id
			createdAt
			updatedAt
		}
	}
`; // codegen unable to generate 5 depth tree

async function fetchOrderList(isDriver, driver) {
	if (isDriver) {
		return await API.graphql(
			graphqlOperation(getDriver, { id: driver.sub })
		);
	}
	return await API.graphql(graphqlOperation(listOrders));
}

const columns = [
	{ field: "dateTime", headerName: "Date Time", width: 150 },
	{ field: "driverName", headerName: "Driver name", width: 200 },
	{
		field: "pickUpLocation",
		headerName: "Pick Up Location",
		width: 200
	},
	{
		field: "dropOfLocation",
		headerName: "Drop Off Location",
		width: 200
	},
	{
		field: "status",
		headerName: "Status",
		width: 200
	}
];

export default function DashBoard({ signOut, user }) {
	console.log({ user });
	const [orderListData, setOrderListData] = React.useState([]);
	const [filteredOrderList, setFilteredOrderList] = React.useState([]);
	const [filterOrderBy, setFilterOrderBy] = React.useState(STATUS.draft);
	const [isDriver, setIsDriver] = React.useState(
		getUserGroup().includes("Driver")
	);
	function getUserGroup() {
		return (
			user.signInUserSession.accessToken.payload["cognito:groups"] || []
		);
	}

	React.useEffect(() => {
		fetchOrderList(isDriver, user.attributes).then((res) => {
			if (isDriver) {
				setOrderListData(
					res.data.getDriver.orders.items.map((order) => ({
						id: order.id,
						dateTime: order.dateTime,
						driverName: order.driver?.fullName,
						pickUpLocation: order.pickUpLocation?.code,
						dropOfLocation: order.dropOffLocation?.code,
						status: order.status,
						rowData: order
					}))
				);
			} else {
				setOrderListData(
					res.data.listOrders.items.map((order) => ({
						id: order.id,
						dateTime: order.dateTime,
						driverName: order.driver?.fullName,
						pickUpLocation: order.pickUpLocation?.code,
						dropOfLocation: order.dropOffLocation?.code,
						status: order.status,
						rowData: order
					}))
				);
			}
		});
		// Subscribe to creation of order
		const subscription = API.graphql(
			graphqlOperation(onCreateOrder)
		).subscribe({
			next: ({
				_,
				value: {
					data: { onCreateOrder: order }
				}
			}) => {
				console.log({ provider, order });
				const res = {
					id: order.id,
					dateTime: order.dateTime,
					driverName: order.driver?.fullName,
					pickUpLocation: order.pickUpLocation?.code,
					dropOfLocation: order.dropOffLocation?.code,
					status: order.status
				};
				setOrderListData((currOrderListData) => [
					...currOrderListData,
					res
				]);
			},
			error: (error) => console.warn(error)
		});
		const subscriptionUpdateOrder = API.graphql(
			graphqlOperation(onUpdateOrder)
		).subscribe({
			next: ({
				_,
				value: {
					data: { onUpdateOrder: order }
				}
			}) => {
				const res = {
					id: order.id,
					dateTime: order.dateTime,
					driverName: order.driver?.fullName,
					pickUpLocation: order.pickUpLocation?.code,
					dropOfLocation: order.dropOffLocation?.code,
					status: order.status,
					rowData: order
				};
				setOrderListData((currOrderListData) =>
					currOrderListData.map((currOrder) => {
						if (currOrder.id === res.id) {
							return res;
						} else {
							return currOrder;
						}
					})
				);
			},
			error: (error) => console.warn(error)
		});
		return () => {
			// Stop receiving data updates from the subscription
			subscription.unsubscribe();
			subscriptionUpdateOrder.unsubscribe();
		};
	}, []);

	React.useEffect(() => {
		if (filterOrderBy.length) {
			setFilteredOrderList(
				orderListData.filter(
					(order) =>
						order.status?.toLowerCase() ===
						filterOrderBy.toLowerCase()
				)
			);
		} else {
			setFilteredOrderList(orderListData);
		}
	}, [orderListData, filterOrderBy]);
	React.useEffect(() => {
		setIsDriver(getUserGroup().includes("Driver"));
	}, [user]);
	React.useEffect(() => {
		if (isDriver) {
			setFilterOrderBy("");
		}
	}, [isDriver]);

	const getOrderLenByStatus = (status) =>
		orderListData.filter((order) => order.status === status).length;
	const statusBoxClickHandler = (status) => {
		if (filterOrderBy === status) {
			setFilterOrderBy("");
		} else {
			setFilterOrderBy(status);
		}
	};
	return (
		<Box sx={{ flexGrow: 1 }}>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<HeaderAppBar signOut={signOut} isDriver={isDriver}>
					{!isDriver && (
						<Box
							sx={{
								flexGrow: 1,
								marginBottom: "30px",
								display: "flex",
								justifyContent: "space-between",
								overflowY: "auto"
							}}
						>
							<Card
								primaryText={"Draft"}
								secondaryText={getOrderLenByStatus(
									STATUS.draft
								)}
								onClick={() =>
									statusBoxClickHandler(STATUS.draft)
								}
								gif={
									"https://media4.giphy.com/media/NwB0W1B2Ab0uF17Lmj/200w.webp?cid=ecf05e472ya387jgip27n15pb8ovwhcbycap2pusmhj4evst&rid=200w.webp&ct=s"
								}
							/>
							<Card
								primaryText={"Un Confirmed"}
								color={"error"}
								secondaryText={getOrderLenByStatus(
									STATUS.unConfirmed
								)}
								onClick={() =>
									statusBoxClickHandler(STATUS.unConfirmed)
								}
								gif={
									"https://media2.giphy.com/media/jrhpLcf9hbKoyvpFRU/200.webp?cid=ecf05e4779nf0y7bdcz43s1bx9pul1ud6wbhmjahrelnf3rp&rid=200.webp&ct=s"
								}
							/>
							<Card
								primaryText={"Confirmed"}
								secondaryText={getOrderLenByStatus(
									STATUS.confirmed
								)}
								onClick={() =>
									statusBoxClickHandler(STATUS.confirmed)
								}
								gif={
									"https://media0.giphy.com/media/AEWrtkY3AVj8oiUuOs/giphy.webp?cid=ecf05e47jh3hjdx59i0h7ugebr3ib4gzr6zfp9ogmukgi3xo&rid=giphy.webp&ct=s"
								}
							/>
							<Card
								primaryText={"In Transit"}
								secondaryText={getOrderLenByStatus(
									STATUS.inTransit
								)}
								onClick={() =>
									statusBoxClickHandler(STATUS.inTransit)
								}
								gif={
									"https://media4.giphy.com/media/W1fnaLpc7uD8dz1wve/200w.webp?cid=ecf05e472kcbthcg65kwi1uolcc2vrbqpw0m0yw2nshlyyek&rid=200w.webp&ct=s"
								}
							/>
							<Card
								primaryText={"Date Picker comming soon"}
								secondaryText={6}
							/>
						</Box>
					)}
					<DataTable
						isDriver={isDriver}
						columns={columns}
						rows={filteredOrderList}
					/>
				</HeaderAppBar>
			</LocalizationProvider>
		</Box>
	);
}
