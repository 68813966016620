import * as React from "react";
import "./styles.css";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import DashBoard from "./DashBoard.js";

Amplify.configure({
	...awsconfig,
	Auth: {
		identityPoolId: awsconfig.aws_cognito_identity_pool_id,
		region: awsconfig.aws_project_region,
		cookieStorage: {
			domain: window.location.hostname,
			path: "/",
			secure: true
		}
	}
});

export default function App() {
	return (
		<Authenticator>
			{({ signOut, user }) => <DashBoard signOut={signOut} user={user} />}
		</Authenticator>
	);
}

/**
 * @type {import('@types/aws-lambda').APIGatewayProxyHandler}
 */
// var aws = require("aws-sdk");
// var ddb = new aws.DynamoDB();

// exports.handler = async (event, context) => {
// 	let date = new Date();
// 	// insert code to be executed by your lambda trigger
// 	if (event.request.userAttributes.sub) {
// 		let params = {
// 			Item: {
// 				id: { S: event.request.userAttributes.sub },
// 				__typename: { S: "Driver" },
// 				phoneNumber: event.request.userAttributes.phone_number,
// 				fullName: { S: event.request.userAttributes.name },
// 				email: { S: event.request.userAttributes.email }
// 			},
// 			TableName: process.env.DRIVERTABLE
// 		};
// 		try {
// 			await ddb.putItem(params).promise();
// 			console.log("successfully added to ddb");
// 		} catch (err) {
// 			console.error("Error while adding to ddb", { err });
// 		}
// 		context.done(null, event);
// 	} else {
// 		console.log("Error: Nothing was written to DynamoDB");
// 		context.done(null, event);
// 	}
// };

// const aws = require("aws-sdk");

// const cognitoidentityserviceprovider = new aws.CognitoIdentityServiceProvider({
// 	apiVersion: "2016-04-18"
// });

// /**
//  * @type {import('@types/aws-lambda').PostConfirmationTriggerHandler}
//  */
// exports.handler = async (event) => {
// 	console.log("test =>>>>>>>>>>>>>>>>>>>>>>>>>>>>. [add to group]");

// 	const groupParams = {
// 		GroupName: process.env.GROUP,
// 		UserPoolId: event.userPoolId
// 	};
// 	const addUserParams = {
// 		GroupName: process.env.GROUP,
// 		UserPoolId: event.userPoolId,
// 		Username: event.userName
// 	};
// 	/**
// 	 * Check if the group exists; if it doesn't, create it.
// 	 */
// 	console.log("Checking a group =>>>>>>>>>>>>>>>>>>>>>>>>>>>>");

// 	try {
// 		await cognitoidentityserviceprovider.getGroup(groupParams).promise();
// 	} catch (e) {
// 		console.log(
// 			"*****************Error a group =>>>>>>>>>>>>>>>>>>>>>>>>>>>>",
// 			{ message: e.message }
// 		);

// 		await cognitoidentityserviceprovider.createGroup(groupParams).promise();
// 	}
// 	/**
// 	 * Then, add the user to the group.
// 	 */
// 	console.log("We have group", addUserParams);

// 	// await cognitoidentityserviceprovider
// 	// 	.adminAddUserToGroup(addUserParams)
// 	// 	.promise();

// 	cognitoidentityserviceprovider.adminAddUserToGroup(
// 		addUserParams,
// 		function (err, data) {
// 			console.log(
// 				"Finally a group =>>>>>>>>>>>>>>>>>>>>>>>>>>>>",
// 				err,
// 				data
// 			);

// 			if (err) console.log(err, err.stack); // an error occurred
// 			else console.log(data); // successful response
// 		}
// 	);

// 	return event;
// };
