import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { CardActionArea } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export default function OutlinedCard({
	primaryText,
	secondaryText,
	gif,
	onClick,
	color
} = {}) {
	const card = (
		<React.Fragment>
			<CardActionArea>
				<CardContent>
					<Typography
						sx={{
							fontSize: 14,
							fontWeight: "bold",
							backgroundColor: "white"
						}}
						color="text.secondary"
						gutterBottom
					>
						{primaryText}
					</Typography>
					<Typography
						textAlign={"center"}
						variant="h2"
						component="div"
						color={color || undefined}
						sx={{
							fontWeight: "bold",
							backgroundColor: "#ffffff5c"
						}}
					>
						{secondaryText}
					</Typography>
				</CardContent>
			</CardActionArea>
		</React.Fragment>
	);
	return (
		<Box
			sx={{
				maxWidth: 300,
				minWidth: 150,
				backgroundImage: `url(${gif})`,
				backgroundSize: "cover"
			}}
			onClick={onClick}
		>
			<Card
				sx={{
					backgroundColor: "inherit"
				}}
				variant="outlined"
			>
				{card}
			</Card>
		</Box>
	);
}
