/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDriver = /* GraphQL */ `
  mutation CreateDriver(
    $input: CreateDriverInput!
    $condition: ModelDriverConditionInput
  ) {
    createDriver(input: $input, condition: $condition) {
      name
      email
      orders {
        items {
          sealNumber
          containerNumber
          note
          dateTime
          type
          pickUpLocation
          dropOffLocation
          status
          id
          createdAt
          updatedAt
          driverOrdersId
        }
        nextToken
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateDriver = /* GraphQL */ `
  mutation UpdateDriver(
    $input: UpdateDriverInput!
    $condition: ModelDriverConditionInput
  ) {
    updateDriver(input: $input, condition: $condition) {
      name
      email
      orders {
        items {
          sealNumber
          containerNumber
          note
          dateTime
          type
          pickUpLocation
          dropOffLocation
          status
          id
          createdAt
          updatedAt
          driverOrdersId
        }
        nextToken
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteDriver = /* GraphQL */ `
  mutation DeleteDriver(
    $input: DeleteDriverInput!
    $condition: ModelDriverConditionInput
  ) {
    deleteDriver(input: $input, condition: $condition) {
      name
      email
      orders {
        items {
          sealNumber
          containerNumber
          note
          dateTime
          type
          pickUpLocation
          dropOffLocation
          status
          id
          createdAt
          updatedAt
          driverOrdersId
        }
        nextToken
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      sealNumber
      containerNumber
      note
      dateTime
      type
      pickUpLocation
      dropOffLocation
      driver {
        name
        email
        orders {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      status
      id
      createdAt
      updatedAt
      driverOrdersId
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      sealNumber
      containerNumber
      note
      dateTime
      type
      pickUpLocation
      dropOffLocation
      driver {
        name
        email
        orders {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      status
      id
      createdAt
      updatedAt
      driverOrdersId
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      sealNumber
      containerNumber
      note
      dateTime
      type
      pickUpLocation
      dropOffLocation
      driver {
        name
        email
        orders {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      status
      id
      createdAt
      updatedAt
      driverOrdersId
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      name
      code
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      name
      code
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      name
      code
      id
      createdAt
      updatedAt
    }
  }
`;
