/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDriver = /* GraphQL */ `
  query GetDriver($id: ID!) {
    getDriver(id: $id) {
      name
      email
      orders {
        items {
          sealNumber
          containerNumber
          note
          dateTime
          type
          pickUpLocation
          dropOffLocation
          status
          id
          createdAt
          updatedAt
          driverOrdersId
        }
        nextToken
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const listDrivers = /* GraphQL */ `
  query ListDrivers(
    $filter: ModelDriverFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDrivers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        email
        orders {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      sealNumber
      containerNumber
      note
      dateTime
      type
      pickUpLocation
      dropOffLocation
      driver {
        name
        email
        orders {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      status
      id
      createdAt
      updatedAt
      driverOrdersId
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        sealNumber
        containerNumber
        note
        dateTime
        type
        pickUpLocation
        dropOffLocation
        driver {
          name
          email
          id
          createdAt
          updatedAt
        }
        status
        id
        createdAt
        updatedAt
        driverOrdersId
      }
      nextToken
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      name
      code
      id
      createdAt
      updatedAt
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        code
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
