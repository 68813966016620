import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Autocomplete from "@mui/material/Autocomplete";
import { API, graphqlOperation } from "aws-amplify";
import { listDrivers, listLocations } from "./graphql/queries";
import { createOrder, updateOrder } from "./graphql/mutations";
import MenuItem from "@mui/material/MenuItem";

export const STATUS = {
	draft: "draft",
	unConfirmed: "unConfirmed",
	confirmed: "confirmed",
	inTransit: "inTransit",
	complete: "complete"
};

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4
};
const getOrderStatus = (orderDriver) => {
	if (orderDriver || orderDriver?.value) {
		return STATUS.unConfirmed;
	}
	return STATUS.draft;
};

async function fetchDriverList() {
	return await API.graphql(graphqlOperation(listDrivers));
}
async function fetchLocationList() {
	return await API.graphql(graphqlOperation(listLocations));
}

export default function ModifyOrderModal(
	{ order, editMode, open, handleClose } = { order: {} }
) {
	const [driverListData, setDriverListData] = React.useState([]);
	const [locationListData, setLocationListData] = React.useState([]);
	const [form, setForm] = React.useState({
		orderType: "",
		orderDriver: null,
		pickUpLocationId: "",
		dropOffLocationId: "",
		sealNumber: "",
		note: "",
		containerNumber: "",
		dateTimePicker: new Date()
	});

	const handleFormChange = (name, value) =>
		setForm({
			...form,
			[name]: value
		});
	const handleFormReset = () =>
		setForm({
			orderType: "",
			orderDriver: null,
			pickUpLocationId: "",
			dropOffLocationId: "",
			sealNumber: "",
			note: "",
			containerNumber: "",
			dateTimePicker: new Date()
		});

	React.useEffect(() => {
		fetchDriverList().then((res) => {
			setDriverListData(
				res.data.listDrivers.items.map((driver) => ({
					label: driver.fullName,
					value: driver.id
				}))
			);
		});
		fetchLocationList().then((res) => {
			setLocationListData(
				res.data.listLocations.items.map((location) => ({
					label: location.code,
					value: location.id
				}))
			);
		});
	}, []);
	React.useEffect(() => {
		setForm({
			orderType: order?.type || "",
			orderDriver: order?.driverOrdersId || null,
			pickUpLocationId: order?.orderPickUpLocationId || "",
			dropOffLocationId: order?.orderDropOffLocationId || "",
			sealNumber: order?.sealNumber || "",
			note: order?.note || "",
			containerNumber: order?.containerNumber || "",
			dateTimePicker: order?.dateTimePicker || new Date()
		});
	}, [order]);
	async function createOrderHandler(input) {
		const newOrder = {
			type: input.orderType,
			driverOrdersId: input.orderDriver?.value,
			orderPickUpLocationId: input.pickUpLocationId || undefined,
			orderDropOffLocationId: input.dropOffLocationId || undefined,
			sealNumber: input.sealNumber,
			note: input.note,
			containerNumber: input.containerNumber,
			dateTime: input.dateTimePicker,
			status: getOrderStatus(input.orderDriver)
		};

		try {
			if (editMode) {
				await API.graphql(
					graphqlOperation(updateOrder, {
						input: { ...newOrder, id: order.id }
					})
				);
			} else {
				await API.graphql(
					graphqlOperation(createOrder, { input: newOrder })
				);
			}
			handleClose();
			handleFormReset();
		} catch (error) {
			console.log({ error });
		}
	}
	const {
		orderType,
		dateTimePicker,
		orderDriver,
		pickUpLocationId,
		dropOffLocationId,
		containerNumber,
		sealNumber,
		note
	} = form;
	return (
		<Modal
			open={Boolean(open)}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<Typography id="modal-modal-title" variant="h6" component="h2">
					{editMode ? "Edit Order" : "Create Order"}
				</Typography>
				<Grid
					container
					spacing={2}
					id="modal-modal-description"
					sx={{ mt: 2 }}
				>
					<Grid item xs={12} md={6}>
						<DateTimePicker
							label="Date&Time picker"
							value={dateTimePicker}
							onChange={(value) =>
								handleFormChange("dateTimePicker", value)
							}
							renderInput={(params) => <TextField {...params} />}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							fullWidth
							select
							label="Order Type"
							variant="outlined"
							value={orderType}
							onChange={({ target: { value } }) =>
								handleFormChange("orderType", value)
							}
						>
							{[
								{ label: "Load", value: "load" },
								{ label: "Empty", value: "empty" }
							].map((option) => (
								<MenuItem
									key={option.value}
									value={option.value}
								>
									{option.label}
								</MenuItem>
							))}
						</TextField>
					</Grid>
					<Grid item xs={12} md={6}>
						<Autocomplete
							value={orderDriver}
							onChange={(_, newValue) => {
								handleFormChange("orderDriver", newValue);
							}}
							disablePortal
							options={driverListData}
							renderInput={(params) => (
								<TextField {...params} label="Driver" />
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							value={sealNumber}
							onChange={({ target: { value } }) =>
								handleFormChange("sealNumber", value)
							}
							label="Seal Number"
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							select
							fullWidth
							label="Pick Up Location"
							variant="outlined"
							value={pickUpLocationId}
							onChange={({ target: { value } }) =>
								handleFormChange("pickUpLocationId", value)
							}
						>
							{locationListData.map((option) => (
								<MenuItem
									key={option.value}
									value={option.value}
								>
									{option.label}
								</MenuItem>
							))}
						</TextField>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							select
							fullWidth
							label="Drop off Location"
							variant="outlined"
							value={dropOffLocationId}
							onChange={({ target: { value } }) =>
								handleFormChange("dropOffLocationId", value)
							}
						>
							{locationListData.map((option) => (
								<MenuItem
									key={option.value}
									value={option.value}
								>
									{option.label}
								</MenuItem>
							))}
						</TextField>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							value={containerNumber}
							onChange={({ target: { value } }) =>
								handleFormChange("containerNumber", value)
							}
							label="Container Number"
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							value={note}
							onChange={({ target: { value } }) =>
								handleFormChange("note", value)
							}
							label="Note"
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} alignItems="center">
						<Button
							value="Create Order"
							onClick={() => createOrderHandler(form)}
							variant="contained"
							sx={{ display: "block", margin: "auto" }}
						>
							{editMode ? "Edit Order" : "Create Order"}
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Modal>
	);
}
